import SaveIcon from '@mui/icons-material/Save';
import {Card, Divider, IconButton, TextField, Typography} from '@mui/material';
import Box from '@mui/material/Box';
import React, {useEffect, useRef} from 'react';
import {useMergeClauseMutation, useUpdateClauseMutation, useUpgradeClauseMutation} from '../../../features/Clause/ClauseApiSlice';
import {setEditClauseId, setExecuteScroll, setNewClauseText, setSelectedClauseId} from '../../../features/Clause/ClauseSlice';
import {type Clause} from '../../../features/Clause/types';
import {useUpdateParagraphMutation} from '../../../features/Paragraph/ParagraphApiSlice';
import {setEditParagraphId, setNewParagraphText, setSelectedParagraphId} from '../../../features/Paragraph/ParagraphSlice';
import {type Paragraph} from '../../../features/Paragraph/types';
import {useAppDispatch, useAppSelector} from '../../../hooks/typedReduxHooks';
import AlternativeSelectedBadge from '../alternatives/AlternativeSelectedBadge';
import ClassificationLawyers from './ClassificationLawyers';
import OptionsMenuClause from './OptionsMenuClause';
import OptionsMenuParagraph from './OptionsMenuParagraph';
import TrafficLightIndication from './TrafficLightIndication';
import { useGetReviewsByClauseQuery } from '../../../features/Review/ReviewApiSlice';

interface ParagraphsAndClausesProps {
    paragraphs: Paragraph[]
    isClassification: boolean
    isAlternative?: boolean
}

const ParagraphsAndClauses: React.FC<ParagraphsAndClausesProps> = ({
    paragraphs,
    isClassification,
    isAlternative
}) => {
    const selectedParagraphId: string = useAppSelector((state) => state.paragraph.selectedParagraphId);
    const selectedClauseId: string = useAppSelector((state) => state.clause.selectedClauseId);
    const executeScroll: boolean = useAppSelector((state) => state.clause.executeScroll);

    /* STATES FOR CHANGING TEXTS OF PARAGRAPH/CLAUSE */
    const editClauseId: string = useAppSelector((state) => state.clause.editClauseId);
    const newClauseText: string = useAppSelector((state) => state.clause.newClauseText);
    const editParagraphId: string = useAppSelector((state) => state.paragraph.editParagraphId);
    const newParagraphText: string = useAppSelector((state) => state.paragraph.newParagraphText);

    /* METHODS FOR CHANGING PARAGRAPH/CLAUSE CONTENT */
    const [updateClause] = useUpdateClauseMutation();
    const [updateParagraph] = useUpdateParagraphMutation();
    const [upgradeClause] = useUpgradeClauseMutation();
    const [mergeClause] = useMergeClauseMutation();

    /* SLICE STATE CHANGES */
    const dispatch = useAppDispatch();

    /* SCROLL TO TOP OF PARAGRAPHS AND CLAUSES WHEN FILTER IS CHANGED */
    const filteredAssessment: boolean[] = useAppSelector((state) => state.contract.filteredAssessment);

    // Ref für den Container erstellen
    const containerRef = useRef<HTMLDivElement>(null);

    // useEffect, um auf Änderungen von filteredAssessment zu reagieren
    useEffect(() => {
        if (containerRef.current) {
            containerRef.current.scrollTop = 0;
        }
    }, [filteredAssessment]);

    /* SYNCHRONIZED SCROLLING BETWEEN PDF AND CLAUSES */
    const clauseRefs = useRef<Record<string, React.RefObject<HTMLDivElement>>>({});
    const paragraphRefs = useRef<Record<string, React.RefObject<HTMLDivElement>>>({});

    const { data: reviews = [], refetch: refetchReviews } = useGetReviewsByClauseQuery(selectedClauseId, {
        refetchOnMountOrArgChange: true
    });

    /* REFS FOR SCROLLING */
    paragraphs.forEach(paragraph => {
        paragraph.clauses.forEach(clause => {
            clauseRefs.current[clause._id] = React.createRef();
        });
    });

    paragraphs.forEach(paragraph => {
        paragraphRefs.current[paragraph._id] = React.createRef();
    });

    const onClauseClick = (clauseId: string): void => {
        dispatch(setSelectedClauseId(''));
        dispatch(setSelectedClauseId(clauseId));
    };

    useEffect(() => {
        // scrollToHighlightFromId(selectedClauseId);
        scrollToClause(selectedClauseId, selectedParagraphId);
        // setEditClause(null);
    }, [selectedClauseId, executeScroll]);

    const scrollToClause = (clauseId: string, paragraphId: string): void => {
        if (executeScroll) {
            const clauseElement = clauseRefs.current[clauseId];
            const paragraphElement = paragraphRefs.current[paragraphId];

            if (clauseElement?.current && paragraphElement?.current) {
                const clauseRect = clauseElement.current.getBoundingClientRect();
                const paragraphRect = paragraphElement.current.getBoundingClientRect();
                const containerRect = containerRef.current?.getBoundingClientRect();

                if (containerRect && containerRef.current) {
                    const spaceBelowClause = (paragraphRect.bottom + window.scrollY) - (clauseRect.bottom + window.scrollY);
                    const heightParagraphAndClause = paragraphRect.height - spaceBelowClause;
                    if (heightParagraphAndClause <= containerRect.height) {
                        paragraphElement.current.scrollIntoView({
                            behavior: 'smooth',
                            block: 'start'
                        });
                    } else {
                        clauseElement.current.scrollIntoView({
                            behavior: 'smooth',
                            block: 'center'
                        });
                    }
                }
            }
            dispatch(setExecuteScroll(false));
        }
    };

    /* TEXT CHANGES TO PARAGRAPHS/CLAUSES */
    const handleClauseChange = async (clauseToChange: Clause): Promise<void> => {
        await updateClause({
            clauseId: clauseToChange._id,
            requestBody: {content: newClauseText}
        }).then(() => {
            console.log('Clause changed');
        }).catch((error) => {
            console.log(error);
        });
        await refetchReviews();
        dispatch(setNewClauseText(''));
    };

    const handleParagraphChange = async (paragraphToChange: Paragraph): Promise<void> => {
        await updateParagraph({
            paragraphId: paragraphToChange._id,
            requestBody: {content: newParagraphText}
        }).then(() => {
            console.log('Clause changed');
        }).catch((error) => {
            console.log(error);
        });
        await refetchReviews();
        dispatch(setNewParagraphText(''));
    };

    const handleMergeClause = async (clauseToMerge: Clause): Promise<void> => {
        try {
            const result = await mergeClause(clauseToMerge._id).unwrap();

            if (result) {
                dispatch(setSelectedClauseId(''));
                dispatch(setSelectedClauseId(result));
                await refetchReviews();
            }
        } catch (error) {
            console.log("merge-fehler: ", error);
        } finally {
            await refetchReviews();
        }
    };

    const handleUpgradeClause = async (clauseToUpgrade: Clause): Promise<void> => {
        try {
            await upgradeClause(clauseToUpgrade._id).then(response => {
                console.log(response);
            }).catch(error => {
                console.log(error);
            });
        } catch (error) {
            console.log("upgrade-fehler: ", error);
        } finally {
            await refetchReviews();
        }
    };

    return (
        <Box ref={containerRef} sx={{
            overflow: 'auto',
            height: '100%'
        }}>
            {paragraphs.slice().sort((a, b) => a.position - b.position).map((paragraph, index) => (
                <Card sx={{
                    padding: 2,
                    margin: 2,
                    boxShadow: 'none',
                    border: '2px solid',
                    scrollMarginTop: '10px',
                    borderColor: paragraph._id === selectedParagraphId ? 'lightgrey' : 'transparent',
                    transition: 'transform 0.3s ease',
                    '&:hover': {
                        transform: 'scale(1.007)'
                    }
                }} ref={paragraphRefs.current[paragraph._id]} key={index} onClick={() => {
                    dispatch(setSelectedParagraphId(paragraph._id));
                }}>
                    {editParagraphId === paragraph._id ? (
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                            <TextField variant="standard" sx={{
                                width: '100%'
                            }} defaultValue={paragraph.content} onChange={(e) => {
                                dispatch(setNewParagraphText(e.target.value));
                            }}/>
                            <IconButton onClick={() => {
                                void handleParagraphChange(paragraph);
                                dispatch(setEditParagraphId(''));
                            }}>
                                <SaveIcon/>
                            </IconButton>
                        </Box>) : (
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                            <Typography sx={{
                                fontWeight: 'bold',
                                width: '100%',
                                height: '100%'
                            }}>
                                {paragraph.content}
                            </Typography> {selectedParagraphId === paragraph._id && isClassification ?
                            <OptionsMenuParagraph paragraph={paragraph}/> : <></>}
                        </Box>)} 
                    {paragraph.clauses.slice().sort((a, b) => a.position - b.position).map((clause, index2) => (
                    <Box key={index2}>
                        {index2 !== 0 ?
                            <Divider/> : <></>}
                        <Card key={index2} ref={clauseRefs.current[clause._id]} sx={{
                            padding: 0.5,
                            my: 0.5,
                            width: '100%',
                            margingY: 1, // border: '1px solid',
                            backgroundColor: clause._id === selectedClauseId ? '#F0F8FF' : 'transparent'
                        }} onClick={() => {
                            onClauseClick(clause._id);
                        }}>
                            {editClauseId === clause._id ? (
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}>
                                        <TextField variant="outlined" sx={{
                                            marginTop: 0.5,
                                            width: '100%'
                                        }} defaultValue={clause.content} onChange={(e) => {
                                            dispatch(setNewClauseText(e.target.value));
                                        }}/>
                                        <IconButton onClick={() => {
                                            void handleClauseChange(clause);
                                            dispatch(setEditClauseId(''));
                                        }}>
                                            <SaveIcon/>
                                        </IconButton>
                                    </Box>) :
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    height: '100%'
                                }}>
                                    <TrafficLightIndication clause={clause}/>
                                    <Typography sx={{
                                        width: '100%',
                                        height: '100%',
                                        cursor: 'pointer',
                                        padding: 1,
                                        ml: 1
                                    }}>
                                        {clause.content}
                                    </Typography> {selectedClauseId === clause._id && isClassification ?
                                    <OptionsMenuClause clause={clause} handleMergeClause={handleMergeClause} handleUpgradeClause={handleUpgradeClause}/> : <></>}
                                </Box>} {clause._id === selectedClauseId && isClassification ?
                            <Box>
                                <Divider sx={{
                                    margin: 1
                                }}/>
                                <ClassificationLawyers clause={clause} initReviews={reviews}/>
                            </Box> : <></>} {isAlternative && (
                            <Box my={1}>
                                <AlternativeSelectedBadge clauseId={clause._id}/>
                            </Box>)}
                        </Card>
                    </Box>))}
                </Card>))}
        </Box>);
};

export default ParagraphsAndClauses;
