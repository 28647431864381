import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Card, CardContent, Chip, IconButton, Paper, Tooltip, Typography } from '@mui/material';
import ContractItem from '../contractManagement/ContractItem';
import { Review } from '../../features/Review/types';
import { Contract } from '../../features/Contract/types';
import {useDispatch} from 'react-redux';
import {setFilteredAssessment} from '../../features/Contract/ContractSlice';
import { HelpOutlineOutlined } from '@mui/icons-material';

interface ContractMap {
  [contractId: string]: {
    contract: Contract;
    clauses: {
      _id: string;
      content: string;
    }[];
  };
}

interface ContractAnalysisProps {
  contractMap: ContractMap;
  reviews: Review[];
}

const ContractAnalysis: React.FC<ContractAnalysisProps> = ({
  contractMap,
  reviews
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();


  if (!contractMap || Object.keys(contractMap).length === 0) {
    return (
      <Paper sx={{ p: 3 }} elevation={0}>
        <Typography variant="h5" gutterBottom>
          Verträge mit zu revalidierenden Klauseln
        </Typography>
        <Typography color="text.secondary">
          Keine Verträge mit zu revalidierenden Klauseln vorhanden.
        </Typography>
      </Paper>
    );
  }

  const reviewsByContract = Object.keys(contractMap).reduce((acc, contractId) => {
    const contractReviews = reviews.filter(review => {
      const clause = contractMap[contractId].clauses.find(c => c._id === review.clause);
      return !!clause;
    });
    if (contractReviews.length > 0) {
      acc[contractId] = contractReviews;
    }

    return acc;
  }, {} as { [key: string]: Review[] });

  return (
    <Box sx={{ p: 3 }} alignItems={"center"}>
      <Typography variant="h5" gutterBottom>
        Verträge mit zu revalidierenden Klauseln
        <Tooltip 
          sx={{ padding: 0, marginLeft: 1 }} 
          title={
            <Typography sx={{ margin: 0, padding: 0}}>
              <em>Hier werden Verträge aufgelistet, die Klauseln enthalten, die neu bewertet werden müssen.</em> <br />
              <b>Klicken Sie auf einen Vertrag, um die betroffenen Klauseln und Reviews anzuzeigen.</b>
            </Typography>
          } 
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: 'common.white',
                color: 'text.primary',
                boxShadow: 2,
              },
            },
          }}
        >
          <IconButton>
            <HelpOutlineOutlined />
          </IconButton>
        </Tooltip>
      </Typography>
      
      {Object.entries(contractMap).map(([contractId, { contract, clauses }]) => {
        const contractReviews = reviewsByContract[contractId] || [];
        
        return (
          <Card 
            key={contractId}
            sx={{
              mb: 1,
              '&:hover': {
                bgcolor: 'action.hover',
                cursor: 'pointer'
              },
              transition: 'background-color 0.2s'
            }}
            onClick={() => {
              dispatch(setFilteredAssessment([false, false, false, true]));
              navigate(`/contracts/${contractId}`, { state: { contract } });
            }}
          >
            <CardContent sx={{ display: 'flex', gap: 2 }}>
              <Box sx={{ mb: 3, width: '33%' }}>
                <ContractItem contract={contract} hideOptions={true} />
                <Box sx={{ mt: 2 }}>
                  <Chip
                    label={`${clauses.length} ${clauses.length === 1 ? 'Klausel' : 'Klauseln'} neu bewerten`}
                    color="primary"
                    variant="outlined"
                    size="small"
                  />
                </Box>
              </Box>
              <Box sx={{ width: '66%', maxHeight: '400px', overflow: 'auto' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                  {clauses.map((clause) => {
                    const clauseReviews = contractReviews.filter(review => 
                      review.clause.toString() === clause._id.toString()
                    );
                    
                    return clauseReviews.map((review) => (
                      <Paper
                        key={review._id}
                        variant="outlined"
                        sx={{ 
                          p: 2,
                          bgcolor: 'grey.50',
                          '&:hover': {
                            bgcolor: 'grey.100'
                          }
                        }}
                      >
                        <Typography 
                          variant="body1" 
                          sx={{ 
                            fontWeight: 500,
                            mb: 1
                          }}
                        >
                          {clause.content}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          Review vom {new Date(review.createdAt).toLocaleDateString()}
                        </Typography>
                      </Paper>
                    ));
                  })}
                </Box>
              </Box>
            </CardContent>
          </Card>
        );
      })}
    </Box>
  );
};

export default ContractAnalysis;