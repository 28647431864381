import { apiSlice } from '../../app/api/apiSlice';
import { type Rule, CreateRuleRequest, UpdateRuleRequest } from './types';

export const RuleApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getAllRules: builder.query<Rule[], void>({
            query: () => ({
                url: `/rule`,
                method: 'GET'
            }),
            providesTags: ['Rule']
        }),
        postNewRule: builder.mutation<Rule, CreateRuleRequest>({
            query: (newRule) => ({
                url: `/rule`,
                method: 'POST',
                body: newRule
            }),
            invalidatesTags: ['Rule']
        }),
        updateRule: builder.mutation<Rule, UpdateRuleRequest>({
            query: ({ ruleId, updatedRule }) => ({
                url: `/rule/${ruleId}`,
                method: 'PUT',
                body: updatedRule
            }),
            invalidatesTags: ['Rule']
        }),
        updateRuleArchiveStatus: builder.mutation<void, { ruleId: string; isArchived: boolean }>({
            query: ({ ruleId, isArchived }) => ({
              url: `/rule/${ruleId}/archive`,
              method: 'PUT',
              body: { isArchived },
            }),
            invalidatesTags: ['Rule'],
        }),
        getRuleHistory: builder.query<Rule[], string>({
            query: (ruleId) => `/rule/${ruleId}/history`,
            providesTags: ['Rule']
        })
    })
});

export const {
    useGetAllRulesQuery,
    usePostNewRuleMutation,
    useUpdateRuleMutation,
    useUpdateRuleArchiveStatusMutation,
    useGetRuleHistoryQuery
} = RuleApiSlice;
