import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Typography} from '@mui/material';
import React, {useState} from 'react';
import {useDeleteClauseMutation} from '../../../features/Clause/ClauseApiSlice';
import {setEditClauseId, setNewClauseText} from '../../../features/Clause/ClauseSlice';
import {type Clause} from '../../../features/Clause/types';
import {useAppDispatch} from '../../../hooks/typedReduxHooks';
import ConfirmActionDialog from '../../general/ConfirmActionDialog';

type OptionsMenuClauseProps = {
    clause: Clause;
    handleMergeClause: (clauseToMerge: Clause) => void;
    handleUpgradeClause: (clauseToUpgrade: Clause) => void;
};

const OptionsMenuClause: React.FC<OptionsMenuClauseProps> = ({clause, handleMergeClause, handleUpgradeClause}): any => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    /* SLICE STATE CHANGES */
    const dispatch = useAppDispatch();

    /* CLAUSE MUTATIONS */
    const [deleteClause] = useDeleteClauseMutation();
    // const [upgradeClause] = useUpgradeClauseMutation();
    // const [mergeClause] = useMergeClauseMutation();

    /* STATES FOR DIALOG WINDOW */
    const [openDialog, setOpenDialog] = useState(false);
    const [actionToConfirm, setActionToConfirm] = useState<() => void>(() => {
    });

    /* OPEN AND CLOSE */
    const handleClick = (event: any): void => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (event: any): void => {
        event.stopPropagation();
        setAnchorEl(null);
    };

    /* CHANGE OPERATIONS ON CLAUSE */
    // const handleUpgradeClause = async (clauseToUpgrade: Clause): Promise<void> => {
    //     try {
    //         await upgradeClause(clauseToUpgrade._id).then(response => {
    //             console.log(response);
    //         }).catch(error => {
    //             console.log(error);
    //         });
    //     } catch (error) {
    //         console.log("upgrade-fehler: ", error);
    //     }
    // };

    const handleDeleteClause = async (clauseToDelete: Clause): Promise<void> => {
        await deleteClause(clauseToDelete._id).then(response => {
            console.log(response);
        }).catch(error => {
            console.log(error);
        });
    };

    // const handleMergeClause = async (clauseToMerge: Clause): Promise<void> => {
    //     try {
    //         await mergeClause(clauseToMerge._id).then(response => {
    //             console.log(response);
    //         }).catch(error => {
    //             console.log(error);
    //         });
    //     } catch (error) {
    //         console.log("merge-fehler: ", error);
    //     }
    // };

    /* DIALOG ACTIONS TO CONFIRM CHANGE OPERATION */
    const handleOpenDialog = (action: () => void): void => {
        setOpenDialog(true);
        setActionToConfirm(() => action);
    };

    const handleCloseDialog = (): void => {
        setOpenDialog(false);
        setActionToConfirm(() => {
        });
    };

    const actionText = clause.position === 0 ? ['Klausel Text bearbeiten', 'Zu Paragraphen umwandeln', 'Klausel löschen'] : ['Mit vorheriger Klausel vereinen', 'Text der Klausel bearbeiten', 'Zu Paragraphen umwandeln', 'Klausel löschen'];

    const actionMethods = clause.position === 0 ? [() => {
        dispatch(setEditClauseId(clause._id));
        dispatch(setNewClauseText(clause.content));
    }, () => {
        handleOpenDialog(() => {
            void handleUpgradeClause(clause);
        });
    }, () => {
        handleOpenDialog(() => {
            void handleDeleteClause(clause);
        });
    }] : [() => {
        handleOpenDialog(() => {
            void handleMergeClause(clause);
        });
    }, () => {
        dispatch(setEditClauseId(clause._id));
        dispatch(setNewClauseText(clause.content));
    }, () => {
        handleOpenDialog(() => {
            void handleUpgradeClause(clause);
        });
    }, () => {
        handleOpenDialog(() => {
            void handleDeleteClause(clause);
        });
    }];

    const actionIcons = clause.position === 0 ? [
        <EditIcon key={'Edit'}/>,
        <FileUploadIcon key={'Paragraph'}/>,
        <DeleteIcon fontSize="small" key={'Delete'}/>] : [
        <AddCircleOutlineIcon key={'Merge'}/>,
        <EditIcon key={'Edit'}/>,
        <FileUploadIcon key={'Paragraph'}/>,
        <DeleteIcon fontSize="small" key={'Delete'}/>];

    return (<>
        <ConfirmActionDialog openDialog={openDialog} handleCloseDialog={handleCloseDialog} actionToConfirm={actionToConfirm}/>
        <IconButton onClick={handleClick}>
            <MoreVertIcon/>
        </IconButton>
        <Menu open={open} onClose={handleClose} anchorEl={anchorEl} keepMounted>
            {actionText.map((text, index) =>
                <MenuItem onClick={actionMethods[index]} key={index} sx={{
                    borderRadius: '8px',
                    mx: 1,
                    pr: 0
                }}>
                    <ListItemText>
                        <Typography>
                            {text}
                        </Typography>
                    </ListItemText>
                    <ListItemIcon sx={{
                        ml: 1,
                        mr: 0
                    }}>
                        {actionIcons[index]}
                    </ListItemIcon>
                </MenuItem>)}
        </Menu>
    </>);
};

export default OptionsMenuClause;
