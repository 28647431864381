import {Box, Button, FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField, Typography} from '@mui/material';
import {useFormik} from 'formik';
import React from 'react';
import * as Yup from 'yup';
import {useUpdateUserMutation} from '../../features/User/UserApiSlice';
import {IUser} from "../../features/User/types";

const validationSchema = Yup.object({
    email: Yup.string().email('Invalide Email Adresse').required('Email Erforderlich'),
    firstName: Yup.string().required('Vorname Erforderlich'),
    lastName: Yup.string().required('Nachname Erforderlich'),
    title: Yup.string(),
    position: Yup.string(),
    password: Yup.string().min(10, 'Muss mindestens 10 Zeichen lang sein').matches(/^(?=.*[a-z])/, '1 Kleinbuchstabe Erforderlich').matches(/^(?=.*[A-Z])/, '1 Großbuchstabe Erforderlich').matches(/^(?=.*[0-9])/, '1 Zahl Erforderlich').matches(/^(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])/, '1 Sonderzeichen Erforderlich')
});

interface UpdateUserFormProps {
    handleClose: () => void
    user?: IUser
}

const UpdateUserForm: React.FC<UpdateUserFormProps> = ({
    handleClose,
    user
}) => {
    const [updateUser] = useUpdateUserMutation();

    const formik = useFormik({
        initialValues: {
            email: user?.email ?? '',
            firstName: user?.firstName ?? '',
            lastName: user?.lastName ?? '',
            title: user?.title ?? '',
            position: user?.position ?? '',
            password: ''
        },
        validationSchema,
        onSubmit: (values) => {
            if (values.password === '') {
                delete (values as { password?: string }).password;
            }
            updateUser({
                userId: user?._id ?? '',
                requestBody: values
            }).then(() => {
                handleClose();
            }).catch((err) => {
                console.log(err);
            });
        }
    });

    return (<>
        <Typography variant="h5">Nutzer updaten</Typography>
        <form onSubmit={formik.handleSubmit}>
            <TextField label="Email" variant="outlined" fullWidth margin="normal"{...formik.getFieldProps('email')} error={formik.touched.email && Boolean(formik.errors.email)} helperText={formik.touched.email ? formik.errors.email : ''}/>

            <TextField label="Vorname" variant="outlined" fullWidth margin="normal"{...formik.getFieldProps('firstName')} error={formik.touched.firstName && Boolean(formik.errors.firstName)} helperText={formik.touched.firstName && formik.errors.firstName}/>

            <TextField label="Nachname" variant="outlined" fullWidth margin="normal"{...formik.getFieldProps('lastName')} error={formik.touched.lastName && Boolean(formik.errors.lastName)} helperText={formik.touched.lastName && formik.errors.lastName}/>

            <TextField label="Titel (optional)" variant="outlined" fullWidth margin="normal"{...formik.getFieldProps('title')} error={formik.touched.title && Boolean(formik.errors.title)} helperText={formik.touched.title && formik.errors.title}/>

            <FormControl 
                fullWidth 
                margin="normal"
                error={formik.touched.position && Boolean(formik.errors.position)}
            >
                <InputLabel id="position-label">Position</InputLabel>
                <Select
                    labelId="position-label"
                    label="Position"
                    {...formik.getFieldProps('position')}
                >
                    <MenuItem value="admin">Admin</MenuItem>
                    <MenuItem value="bearbeiter">Bearbeiter</MenuItem>
                </Select>
                {formik.touched.position && formik.errors.position && (<FormHelperText>{formik.errors.position}</FormHelperText>)}
            </FormControl>

            <TextField label="Passwort (optional)" variant="outlined" fullWidth margin="normal" type="password"{...formik.getFieldProps('password')} error={formik.touched.password && Boolean(formik.errors.password)} helperText={formik.touched.password && formik.errors.password}/>
            <Box mt={1}>
                <Button type="submit" variant="contained" color="primary" fullWidth> Nutzer updaten </Button>
            </Box>
        </form>
    </>);
};

export default UpdateUserForm;
