import React, { useEffect, useMemo, useState } from 'react';
import { Box, Typography, Paper, Tabs, Tab, Skeleton } from '@mui/material';
import {
  useGetAnalysisObjectsQuery,
  useGetBasicMetaQuery,
  useGetInvalidReviewsQuery,
  useGetContractByClauseIdsMutation,
  useGetConfusionMatrixDataQuery,
  useGetMetricsDataQuery
} from '../../features/Analysis/AnalysisApiSlice';
import BasicMetas from './BasicMetas';
import ConfidenceScore from './ConfidenceScore';
import RuleCorpusSize from './RuleCorpusSize';
import ContractAnalysis from './ContractAnalysis';
import ConfusionMatrix from './ConfusionMatrix';
import AccuracyScore from './AccuracyScore';
import TimePerContract from './TimePerContract';
import RuleCoverage from './RuleCoverage';
import { Review } from '../../features/Review/types';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const NoDataAvailable = ({ metricName, ifNoDataText }: { metricName: string, ifNoDataText?: string }) => (
  <Paper sx={{ p: 3 }} elevation={0}>
    <Typography variant="h5" gutterBottom>
      {metricName}
    </Typography>
    <Typography color="text.secondary">
      {ifNoDataText || 'Hierfür gibt es bisher nicht genug Daten.'}
    </Typography>
  </Paper>
);

const TabPanel: React.FC<TabPanelProps> = ({ children, value, index, ...other }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`analysis-tabpanel-${index}`}
    aria-labelledby={`analysis-tab-${index}`}
    {...other}
  >
    {value === index && (
      <Box sx={{ pt: 3 }}>
        {children}
      </Box>
    )}
  </div>
);

const AnalysisPage = () => {
  const [tabValue, setTabValue] = useState(0);
  const [contractMapData, setContractMapData] = useState<any>([]);

  const dateRange = useMemo(() => ({
    from: new Date(Date.now() - 365 * 24 * 60 * 60 * 1000),
    to: new Date()
  }), []);

  const basicMetaQuery = useGetBasicMetaQuery();
  const metricsDataQuery = useGetMetricsDataQuery(null);
  const analysisDataQuery = useGetAnalysisObjectsQuery();
  const invalidReviewsQuery = useGetInvalidReviewsQuery();
  const confusionMatrixQuery = useGetConfusionMatrixDataQuery(dateRange);

  const [getContractByClauseIds, { isLoading: isContractLoading, isError: isContractError }] = useGetContractByClauseIdsMutation();

  useEffect(() => {
    const fetchContracts = async () => {
      if (!invalidReviewsQuery.isLoading && !invalidReviewsQuery.isError && invalidReviewsQuery.data) {
        try {
          const clauseIds = invalidReviewsQuery.data.map((review: Review) => review.clause);
          const result = await getContractByClauseIds(clauseIds).unwrap();
          setContractMapData(result);
        } catch (error) {
          console.error('Error fetching contracts:', error);
        }
      }
    };

    fetchContracts();
  }, [invalidReviewsQuery.data, invalidReviewsQuery.isLoading, invalidReviewsQuery.isError, getContractByClauseIds]);

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      maxHeight: '90vh',
      mt: 5,
    }}>
      <Box sx={{
        mt: 5,
        width: '100%',
        maxHeight: '90vh',
        overflowY: 'auto',
      }}>
        <Typography variant="h4" gutterBottom>Vertragsanalyse</Typography>

        <Box sx={{ width: '100%' }}>
          <Tabs
            value={tabValue}
            onChange={(_, newValue) => setTabValue(newValue)}
            variant="fullWidth"
          >
            <Tab label="Systeminformationen" />
            <Tab label="Detailinformationen" />
            <Tab label="Vertragsanalyse" />
          </Tabs>
        </Box>

        <TabPanel value={tabValue} index={0}>
          <Box sx={{ width: '60%', mx: 'auto' }}>
            <Box sx={{ py: 2, mt: 2 }}>
              {(!basicMetaQuery.data) ? (
                <NoDataAvailable metricName='Basic Informationen' />
              ) : (
                <BasicMetas basicMeta={basicMetaQuery.data} />
              )}
            </Box>

            <Paper sx={{ p: 2, mt: 2 }} elevation={0}>
              {(!metricsDataQuery.data) ? (
                <NoDataAvailable metricName='Durchschnittliche Zeit pro Vertrag (LLM only)' />
              ) : (
              <TimePerContract metricsData={metricsDataQuery.data} />
              )} 
            </Paper>

            <Paper sx={{ p: 2, mt: 2 }} elevation={0}>
              {(!basicMetaQuery.data || basicMetaQuery.data.rules.length === 0) ? (
                <NoDataAvailable metricName='Anzahl der Prüfanleitungen im Zeitverlauf' />
              ) : (
                <RuleCorpusSize analysisData={basicMetaQuery.data.rules} />
              )} 
            </Paper>

            <Paper sx={{ p: 2, mt: 2 }} elevation={0}>
              {(!basicMetaQuery.data || basicMetaQuery.data.rules.length === 0) ? (
                <NoDataAvailable metricName="Prüfanleitungen pro Thema" />
              ) : (
                <RuleCoverage analysisData={basicMetaQuery.data.rules} />
              )} 
            </Paper>
          </Box>
        </TabPanel>

        <TabPanel value={tabValue} index={1}>
          <Box sx={{ width: '60%', mx: 'auto' }}>
            <Paper sx={{ p: 2, mt: 2 }} elevation={0}>
              {(!confusionMatrixQuery.data || confusionMatrixQuery.data.length === 0) ? (
                <NoDataAvailable metricName='Genauigkeit der LLMs im Zeitverlauf' />
              ) : (
                <AccuracyScore assessmentPairs={confusionMatrixQuery.data} />
              )} 
            </Paper>

            <Paper sx={{ p: 2, mt: 2 }} elevation={0}>
              {(!analysisDataQuery.data || analysisDataQuery.data === 0) ? (
                <NoDataAvailable metricName='Confidence Score der Prüfanleitungen' />
              ) : (
                <ConfidenceScore analysisData={analysisDataQuery.data} />
              )} 
            </Paper>

            <Paper sx={{ p: 2, mt: 2 }} elevation={0}>
              {(!confusionMatrixQuery.data) ? (
                <NoDataAvailable metricName='Confusion Matrix' />
              ) : (
                <ConfusionMatrix assessmentPairs={confusionMatrixQuery.data} />
              )} 
            </Paper>
          </Box>
        </TabPanel>

        <TabPanel value={tabValue} index={2}>
          <Box sx={{ width: '60%', mx: 'auto' }}>
            <Paper sx={{ p: 2, mt: 2 }} elevation={0}>
              <ContractAnalysis 
                contractMap={contractMapData} 
                reviews={invalidReviewsQuery.data || []} 
              />
            </Paper>
          </Box>
        </TabPanel>
      </Box>
    </Box>
  );
};

export default AnalysisPage;