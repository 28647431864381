import React, { useMemo, useState, useEffect } from 'react';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import ArchiveIcon from '@mui/icons-material/Archive';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Autocomplete, Box, Button, CircularProgress, IconButton, InputBase, Paper, Typography, useTheme } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import RuleTable from "./RuleTable";
import { useUpdateRuleArchiveStatusMutation, useGetAllRulesQuery, usePostNewRuleMutation, useUpdateRuleMutation } from "../../features/Rule/RuleApiSlice";
import { useGetAllTopicsQuery, useCreateTopicMutation } from "../../features/Topic/TopicApiSlice";
import { createTopicRuleObject } from '../../helper/topicRuleMap';
import { useGetCurrentUserQuery } from '../../features/User/UserApiSlice';
import { Rule } from "../../features/Rule/types";
import { Topic } from "../../features/Topic/types";

const RuleOverview = (): JSX.Element => {
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState("");
    const [showArchived, setShowArchived] = useState(false);
    const [filterInProgress, setFilterInProgress] = useState(false);

    // Queries
    const { data: rules, isLoading: rulesLoading, refetch: refetchRules } = useGetAllRulesQuery();
    const { data: currentUser } = useGetCurrentUserQuery();
    const { data: topics, refetch: refetchTopics } = useGetAllTopicsQuery();

    // Mutations
    const [updateRuleArchiveStatus] = useUpdateRuleArchiveStatusMutation();
    const [createRule, { isLoading: createLoading }] = usePostNewRuleMutation();
    const [updateRule, { isLoading: updateLoading }] = useUpdateRuleMutation();
    const [createTopic] = useCreateTopicMutation();

    // Initial state for current rule
    const [currentRule, setCurrentRule] = useState<Partial<Rule>>({
        topic: "",
        text: "",
        references: [],
        isSyntactic: false,
        isArchived: false
    });

    const [localTopics, setLocalTopics] = useState<Topic[]>([]);

    useEffect(() => {
        if (topics) {
            setLocalTopics(topics.map(topic => topic));
        }
    }, [topics]);

    const onlyTopicTitle = useMemo(() => {
        return topics?.map(topic => topic.title);
    }, [topics]);

    const topicRules = useMemo(() => {
        if (!rules || !topics) return {};
        return createTopicRuleObject(rules, topics);
    }, [rules, topics]);

    const [localRules, setLocalRules] = useState<Rule[]>([]);

    // Process rules with topic titles
    const filterRules = useMemo(() => {
        if (!topicRules || filterInProgress) {
            return [];
        }

        setFilterInProgress(true);
        
        try {
            // Flatten rules from all topics
            const allRules = Object.entries(topicRules).flatMap(([topicTitle, rules]) => 
                rules.map(rule => ({
                    ...rule,
                    topic: topicTitle // Use the topic title instead of ID
                }))
            );

            const combinedRules = [...allRules, ...localRules];
            // Apply search filter
            let filtered = combinedRules;
            
            if (search) {
                filtered = allRules.filter(rule => 
                    rule.topic.toLowerCase().includes(search.toLowerCase()) ||
                    rule.text.toLowerCase().includes(search.toLowerCase()) ||
                    rule.references?.some(ref => 
                        ref.toLowerCase().includes(search.toLowerCase())
                    )
                );
            } else {
                // Only filter by archive status if not searching
                filtered = combinedRules.filter(rule => 
                    rule.isArchived === showArchived
                );
            }

            return filtered;
        } finally {
            setFilterInProgress(false);
        }
    }, [topicRules, search, showArchived]);

    // Handle archive toggle
    const handleArchiveToggle = async (ruleId: string): Promise<void> => {
        try {
            await updateRuleArchiveStatus({
                ruleId,
                isArchived: !showArchived // Toggle the archive status
            });
        } catch (error) {
            console.error('Failed to update archive status:', error);
        }
    };

    // Reference management functions
    const handleAddReference = () => {
        setCurrentRule(prev => ({
            ...prev,
            references: [...(prev.references || []), ""]
        }));
    };

    const handleRemoveReference = (index: number) => {
        setCurrentRule(prev => ({
            ...prev,
            references: (prev.references || []).filter((_, i) => i !== index)
        }));
    };

    const handleReferenceChange = (index: number, value: string) => {
        setCurrentRule(prev => ({
            ...prev,
            references: (prev.references || []).map((ref, i) => 
                i === index ? value : ref
            )
        }));
    };

    // Modal and form handling
    const handleCreateNewButton = (): void => {
        setCurrentRule({
            topic: "",
            text: "",
            references: [],
        });
        setOpen(true);
    };

    const handleClose = (): void => {
        setOpen(false);
    };

    const filter = createFilterOptions<string | Topic>();

    const saveEntry = async (): Promise<void> => {
        try {
            if (!currentRule.topic || !currentRule.text) {
                return;
            }
            const topicObj = localTopics.find(t => t.title === currentRule.topic);
                let topicId = topicObj?._id;
                // wenn es noch nicht existiert, dann erstelle das Topic
                if (!topicObj) {
                    // createTopic gibt ein Topic-Objekt zurück
                    const result = await createTopic(currentRule.topic);
                    if ('data' in result) {
                        setLocalTopics(prev => [...prev, result.data]);
                        topicId = result.data._id;
                    } else {
                        console.error("Failed to create topic:", result.error);
                        return;
                    }
            }
            // Updating existing rule
            if (currentRule.ruleId) {
                await updateRule({
                    ruleId: currentRule.ruleId,
                    updatedRule: {
                        text: currentRule.text || "",
                        topic: topicId as string,
                        references: currentRule.references?.filter(ref => ref.trim() !== "") || [],
                        author: currentUser?._id || "",
                        isSyntactic: currentRule.isSyntactic || false
                    }
                });
                handleClose();

            // Creating new rule with existing topic in localTopics
            } else if (currentRule.topic && currentRule.text && currentUser) {
                // currentRule.topic ist der richtige Titel des Topics.
                // gibt es dieses topic schon in den localRules?
                await createRule({
                    topic: topicId as string,
                    text: currentRule.text,
                    references: currentRule.references?.filter(ref => ref.trim() !== "") || [],
                    author: currentUser._id
                });
    
                handleClose();
            }
        } catch (error) {
            console.error("Failed to save rule:", error);
        } finally {
            await refetchRules();
            await refetchTopics();
        }
    };

    // Search handling
    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    };

    const handleEditButton = async (rule: Rule): Promise<void> => {
        setCurrentRule({
            ...rule,
            isSyntactic: false
        });
        setOpen(true);
    };

    if (rulesLoading) {
        return <CircularProgress />;
    }

    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
        }}>
            <Box sx={{
                mt: 5,
                width: '100%'
            }}>
                <Typography variant="h4">Prüfanleitungen</Typography>

                <Dialog fullWidth={true} maxWidth={'md'} open={open} onClose={handleClose}>
                    <DialogContent sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}>
                        <Box sx={{
                            width: '100%',
                            margin: 'auto'
                        }}>
                            <Typography variant="h5" sx={{ marginBottom: '1rem' }}>{currentRule._id ? "Regel bearbeiten" : "Neue Regel erstellen"}</Typography>
                            <Autocomplete<string | Topic, false, false, true>
                                value={currentRule.topic || ''}
                                onChange={(_, newValue) => {
                                    setCurrentRule({ ...currentRule, topic: typeof newValue === 'string' ? newValue : (newValue as Topic)?.title || '' });
                                }}
                                filterOptions={(options, params) => {
                                    const filtered = filter(options, params);
                                    const { inputValue } = params;

                                    // Prevent duplicate values
                                    const isExisting = (onlyTopicTitle ?? []).some(
                                        title => title.toLowerCase() === inputValue.toLowerCase()
                                    );
                                    
                                    // Add new option if it doesn't exist
                                    if (inputValue !== '' && !isExisting && typeof inputValue === 'string') {
                                        filtered.push(inputValue);
                                    }

                                    return filtered;
                                }}
                                options={localTopics.map(topic => topic.title)}
                                freeSolo
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Thema (zB.: Vergütung)"
                                        margin="normal"
                                        fullWidth
                                        sx={{
                                            width: '100%',
                                            marginBottom: '1rem',
                                            display: 'block',
                                            overflowWrap: 'break-word'
                                        }}
                                    />
                                )}
                            />
                            <TextField value={currentRule.text} label="Regeln... (zB.: Umfang von über 8 Stunden pro Monat als abgegoltene Überstunden ist unzulässig.)" multiline rows={4} fullWidth margin="normal" sx={{
                                width: '100%',
                                marginBottom: '1rem',
                                display: 'block',
                                overflowWrap: 'break-word'
                            }} onChange={(e) => {
                                const newRule = { ...currentRule };
                                newRule.text = e.target.value
                                setCurrentRule(newRule);
                            }} />
                            <Box sx={{ mt: 2 }}>
                            <Typography variant="subtitle1" sx={{ mb: 1 }}>Referenzen</Typography>
                                {currentRule.references?.map((reference, index) => (
                                    <Box key={index} sx={{ display: 'flex', gap: 1, mb: 1 }}>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            value={reference}
                                            onChange={(e) => handleReferenceChange(index, e.target.value)}
                                            label={`Referenz ${index + 1}`}
                                        />
                                        <IconButton 
                                            onClick={() => handleRemoveReference(index)}
                                            color="error"
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Box>
                                ))}
                                <Button
                                    startIcon={<AddIcon />}
                                    onClick={handleAddReference}
                                    variant="outlined"
                                    size="small"
                                    sx={{ mt: 1 }}
                                >
                                    Mehr Referenzen
                                </Button>
                            </Box>

                            {currentRule._id &&
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={currentRule.isSyntactic || false}
                                            onChange={(e) => {
                                                setCurrentRule({ ...currentRule, isSyntactic: e.target.checked });
                                            }}
                                        />
                                    }
                                    label="Keine semantische Änderung"
                                />
                            }
                        </Box>

                        <Box sx={{
                            width: '100%',
                            maxWidth: '300px',
                            margin: 'auto',
                            marginTop: '2rem'
                        }}>
                            <Button type="submit" variant="contained" color="primary" onClick={() => {
                                saveEntry().catch((error) => {
                                    console.log(error);
                                });
                            }} fullWidth sx={{ width: '100%' }}>
                                {createLoading || updateLoading ?
                                    <CircularProgress color="secondary" /> : 'Speichern'}
                            </Button>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Abbrechen</Button>
                    </DialogActions>
                </Dialog>

                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}>
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        {!showArchived && (
                            <Button 
                                variant="outlined" 
                                startIcon={<AddIcon />} 
                                sx={{ my: 4 }} 
                                onClick={handleCreateNewButton}
                            >
                                Neue Regel
                            </Button>
                        )}
                        <Button
                            variant="outlined"
                            startIcon={showArchived ? <UnarchiveIcon /> : <ArchiveIcon />}
                            sx={{ my: 4 }}
                            onClick={() => setShowArchived(!showArchived)}
                            color={showArchived ? "primary" : "secondary"}
                        >
                            {showArchived ? "Aktuelle anzeigen" : "Archiv anzeigen"}
                        </Button>
                    </Box>
                    <Paper component="form" sx={{
                        p: '2px 4px',
                        display: 'flex',
                        alignItems: 'center',
                        backgroundColor: theme.palette.tertiary.main,
                        boxShadow: 'none',
                        height: 'fit-content',
                    }}>
                        <InputBase sx={{
                            ml: 1,
                            flex: 1
                        }} placeholder="Suche eingeben" inputProps={{ 'aria-label': 'Suche eingeben' }} value={search} onChange={handleSearchChange} onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                                event.preventDefault();
                            }
                        }} />
                        <IconButton type="button" sx={{ p: '10px' }} aria-label="Suche">
                            <SearchIcon />
                        </IconButton>
                    </Paper>
                </Box>

                <RuleTable rules={filterRules} handleArchiveToggle={handleArchiveToggle} showArchived={showArchived} handleEdit={handleEditButton} />
            </Box>
        </Box>
    );
};

export default RuleOverview;
