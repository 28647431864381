import { apiSlice } from '../../app/api/apiSlice';
import { ContractClauseMapping, ContractMetrics } from './types';

const formatDateForUrl = (date: Date): string => {
    return date.toISOString().split('T')[0];
};

export const AnalysisApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getAnalysisObjects: builder.query<any, void>({
            query: () => `/analysis`
        }),
        getAnalysisObjectById: builder.query<any, string>({
            query: (id) => `/analysis/${id}`
        }),
        getAnalysisObjectByContractId: builder.query<any, string>({
            query: (id) => `/analysis/byContractId/${id}`
        }),
        getBasicMeta: builder.query<any, void>({
            query: () => `/analysis/basicmeta`
        }),
        getInvalidReviews: builder.query<any, void>({
            query: () => `/analysis/invalidReviews`
        }),
        getContractByClauseIds: builder.mutation<any, ContractClauseMapping[]>({
            query: (ids) => ({
                url: `/analysis/contracts`,
                method: 'POST',
                body: ids
            })
        }),
        getConfusionMatrixData: builder.query<any, {from?: Date, to?: Date}>({
            query: ({from, to}) => {
                const params = new URLSearchParams();
                
                if (from) {
                    params.append('from', formatDateForUrl(from));
                }
                if (to) {
                    params.append('to', formatDateForUrl(to));
                }

                return `/analysis/confusionmatrixdata?${params.toString()}`;
            },
            transformResponse: (response: any) => {
                if (!response) return [];
                return response;
            },
        }),
        getMetricsData: builder.query<ContractMetrics[], string | null>({
            query: (modelName) => {
                if (modelName) {
                    return `/analysis/metrics?modelName=${modelName}`;
                }
                return '/analysis/metrics';
            }
        })
    })
});

export const {
    // useGetFinishedClausesQuery,
    useGetAnalysisObjectsQuery,
    useGetAnalysisObjectByIdQuery,
    useGetAnalysisObjectByContractIdQuery,
    useGetBasicMetaQuery,
    useGetInvalidReviewsQuery,
    useGetContractByClauseIdsMutation,
    useGetConfusionMatrixDataQuery,
    useGetMetricsDataQuery
} = AnalysisApiSlice;