import React, {useState} from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {Avatar, Box, Button, Container, FormControl, TextField, Typography} from '@mui/material';
import {useLoginMutation} from '../../features/User/UserApiSlice';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LoadingSpinner from '../general/LoadingSpinner';


const validationSchema = Yup.object({
    email: Yup.string().email('Invalid email').required('Email is required'),
    password: Yup.string().required('Password is required')
});

const Login: React.FC<any> = () => {
    const [login, {isLoading}] = useLoginMutation();

    const [errMsg, setErrMsg] = useState<string>('');

    // Formik hook
    const formik = useFormik({
        initialValues: {
            email: '',
            password: ''
        },
        validationSchema,
        onSubmit: async (values) => {
            try {
                const result = await login(values);
                if ('data' in result) {
                    window.location.reload();
                } else
                if ('error' in result) {
                    setErrMsg('Login fehlgeschlagen');
                }
            } catch (error) {
                setErrMsg('Login fehlgeschlagen. Error.');
            }
        }
    });

    return (
        <Container component="main" maxWidth="xs">
            <Box sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            }}>
                <Avatar sx={{
                    m: 1,
                    bgcolor: '#5386eb'
                }}>
                    <LockOutlinedIcon/>
                </Avatar>
                <Typography component="h1" variant="h5"> Login </Typography>
                <Box mt={1}>
                    <form onSubmit={formik.handleSubmit}>
                        <FormControl fullWidth margin="normal">
                            <TextField id="email" name="email" label="Email" variant="outlined" autoFocus value={formik.values.email} onChange={formik.handleChange} error={formik.touched.email && Boolean(formik.errors.email)} helperText={formik.touched.email && formik.errors.email}/>
                        </FormControl>

                        <FormControl fullWidth margin="normal">
                            <TextField id="password" name="password" label="Password" type="password" variant="outlined" value={formik.values.password} onChange={formik.handleChange} error={formik.touched.password && Boolean(formik.errors.password)} helperText={formik.touched.password && formik.errors.password}/>
                        </FormControl> {errMsg && (
                        <Typography sx={{
                            mt: 2,
                            color: 'red'
                        }}>{errMsg}</Typography>)}
                        <Button color="primary" variant="contained" fullWidth type="submit" sx={{
                            mt: 3,
                            mb: 2,
                            bgcolor: '#13274F'
                        }}>
                            {isLoading ?
                                <LoadingSpinner/> :
                                <Typography>Login</Typography>}
                        </Button>
                    </form>
                </Box>
            </Box>
        </Container>);
};

export default Login;
