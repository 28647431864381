import {Chip, colors} from '@mui/material';
import React from 'react';
import {type ContractState} from '../../features/Contract/types';

interface WorkflowStateBadgeProps {
    contractState: ContractState
}

interface StateProperties {
    text: string
    backgroundColor: string
    color: string
}

const WorkflowStateBadge: React.FC<WorkflowStateBadgeProps> = ({contractState}) => {
    const stateProperties: Record<ContractState, StateProperties> = {
        CREATED: {
            text: 'Vertrag erstellt',
            backgroundColor: '#f0f0f0',
            color: '#333'
        },
        PDF_UPLOADED: {
            text: 'PDF hochgeladen',
            backgroundColor: '#e6e6e6',
            color: '#444'
        },
        NOT_DELETEABLE: {
            text: 'Vertrag wird verarbeitet',
            backgroundColor: '#fef3e6',
            color: '#ff8c00'
        },
        OCR_DONE: {
            text: 'LLM Bewertung läuft',
            backgroundColor: '#fff9e6',
            color: '#e6ac00'
        },
        LLM_CLASSIFICATION_DONE: {
            text: 'LLM Bewertung abgeschlossen',
            backgroundColor: '#ffedb3',
            color: '#e6ac00'
        },
        FINISHED_REVIEW: {
            text: 'Anwaltliche Bewertung abgeschlossen',
            backgroundColor: '#e8fff2',
            color: '#47c78d'
        },
        NOT_YET_FINISHED: {
            text: 'Anwaltliche Bewertung nicht abgeschlossen',
            backgroundColor: '#ffcccc',
            color: '#cc0000'
        },
        ALTERNATIVE_FORMULATIONS: {
            text: 'Alternativen werden ausgewählt',
            backgroundColor: '#e6f0ff',
            color: '#3366cc'
        },
        FINISHED: {
            text: 'Vertrag abgeschlossen',
            backgroundColor: '#007f5f',
            color: '#fff'
        }
    };

    return (
        <Chip label={stateProperties[contractState]?.text ?? contractState} sx={{
            backgroundColor: stateProperties[contractState]?.backgroundColor ?? '#faf2e5',
            color: stateProperties[contractState]?.color ?? '#e1c055',
            border: `1px solid ${stateProperties[contractState]?.color ?? '#e1c055'}`,
            borderRadius: '8px',
            width: '100%'
        }}/>);
};

export default WorkflowStateBadge;
