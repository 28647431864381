import {Box, Grid} from '@mui/material';
import React, {useEffect, useState} from 'react';
import ContractOcrDisplay from './classification/ContractOcrDisplay';
import { useGetContractByIdQuery } from '../../features/Contract/ContractApiSlice';
import { useParams } from 'react-router-dom';
import ContractInfo from './ContractInfo';
import WorkflowStepper from './WorkflowStepper';
import AlternativePage from './alternatives/AlternativePage';
import LoadingComponent from '../general/LoadingComponent';
import {ContractState} from '../../features/Contract/types';

const ContractWorkflow = (): any => {
    const { _id } = useParams();

    const { data: contract, isLoading: isContractLoading, refetch: refetchContract } = useGetContractByIdQuery(_id);

    const [activeStep, setActiveStep] = useState(0);

    const handleNext = (): void => {
        if (activeStep !== 1) {
            setActiveStep((prevActiveStep: any) => prevActiveStep + 1);
        } else {
            // START DOWNLOAD OF CONTRACT
        }
    };

    const handleBack = (): void => {
        setActiveStep((prevActiveStep: any) => prevActiveStep - 1);
    };

    useEffect(() => {
        const interval = setInterval(() => {
            if (!contract || contract.state === ContractState.OCR_DONE || contract.state === ContractState.LLM_CLASSIFICATION_DONE || contract.state === ContractState.NOT_DELETEABLE) {
                refetchContract().catch(() => { });
            }
        }, 2000);

        return () => {
            clearInterval(interval);
        };
    }, [contract, refetchContract]);

    return (
        <Box sx={{ width: '100%' }}>
            <Grid container spacing={2} justifyContent={'center'} my={2}>
                <Grid item xs={6} md={6} lg={6}>
                    <ContractInfo/>
                </Grid>
                <Grid item xs={6} md={6} lg={6}>
                    <WorkflowStepper activeStep={activeStep} handleNext={handleNext} handleBack={handleBack}/>
                </Grid>
            </Grid>
            {(!isContractLoading && contract) ? (
                <>
                    {contract.state === ContractState.NOT_DELETEABLE ?  <LoadingComponent text="Vertrag wird vorbereitet..."/>
                        : contract.state === ContractState.PDF_UPLOADED ? <LoadingComponent text="OCR wird durchgeführt..."/>
                        : contract.state === ContractState.OCR_DONE ? <LoadingComponent text="Vertrag wird von AI klassifiziert..."/>
                        :
                            <>
                                {activeStep === 0 && <ContractOcrDisplay/>}
                                {activeStep === 1 && <AlternativePage/>}
                            </>}
                </>
            ) : <LoadingComponent text="Lade Vertrag..."/>}
        </Box>);
};

export default ContractWorkflow;
