import {apiSlice} from '../../app/api/apiSlice';
import {type Paragraph} from '../Paragraph/types';
import {type Contract, type ContractState} from './types';

export const ContractApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getAllContracts: builder.query<Contract[], any>({
            query: () => ({
                url: `/contracts`,
                method: 'GET'
            }),
            providesTags: ['Contract']
        }),
        createContract: builder.mutation<Contract, FormData>({
            query: (formData) => ({
                url: `/contracts`,
                method: 'POST',
                body: formData,
            }),
            invalidatesTags: ['Contract']
        }),
        getContractById: builder.query<Contract, string | undefined>({
            query: (_id) => `/contracts/${_id}`,
            providesTags: (result) => result != null ? [{
                type: 'Contract',
                id: result._id
            }] : []
        }),
        updateContract: builder.mutation<any, { contractId: string, requestBody: { state?: ContractState } }>({
            query: ({
                contractId,
                requestBody
            }) => ({
                url: `/contracts/${contractId}`,
                method: 'PUT',
                body: requestBody,
                invalidatesTags: ['Contract']
            })
        }),
        getContractsByUser: builder.query<Contract[], string>({
            query: (userId) => `/contracts/byUser/${userId}`,
            providesTags: ['Contract']
        }),
        getParagraphsAndClauses: builder.query<Paragraph[], string>({
            query: (contractId) => ({
                url: `/contracts/paragraphsAndClauses/${contractId}`,
                method: 'GET'
            }),
            providesTags: ['Paragraph', 'Clause']
        }),
        classifyAllClauses: builder.mutation<any, string>({
            query: (contractId) => ({
                url: `/contracts/classifyAllClauses/${contractId}`,
                method: 'POST'
            }),
            invalidatesTags: ['Contract', 'Paragraph', 'Clause']
        }),
        resetContract: builder.mutation<any, {contractId: string, targetState: ContractState}>({
            query: ({contractId, targetState}) => ({
                url: `/contracts/reset/${contractId}`,
                method: 'PATCH',
                body: { targetState }
            }),
            invalidatesTags: ['Contract', 'Paragraph', 'Clause']
        }),
        deleteContract: builder.mutation<any, any>({
            query: (contractId) => ({
                url: `/contracts/${contractId}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['Contract']
        })
    })
});

export const {
    useGetAllContractsQuery,
    useCreateContractMutation,
    useGetContractByIdQuery,
    useUpdateContractMutation,
    useLazyGetContractsByUserQuery,
    useGetParagraphsAndClausesQuery,
    useClassifyAllClausesMutation,
    useResetContractMutation,
    useDeleteContractMutation
} = ContractApiSlice;

export const {
    endpoints: {
        getAllContracts,
        getContractById,
        createContract,
        updateContract,
        getContractsByUser,
        getParagraphsAndClauses,
        classifyAllClauses,
        resetContract,
        deleteContract
    }
} = ContractApiSlice;
