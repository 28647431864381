export enum ContractState {
    CREATED = 'CREATED',

    // PDF is uploaded => OCR läuft…
    PDF_UPLOADED = 'PDF_UPLOADED',

    // OCR is done => LLM klassifiziert…
    OCR_DONE = 'OCR_DONE',
    
    // AI classified => LLM hat Klassifizierung abgeschlossen
    LLM_CLASSIFICATION_DONE = 'LLM_CLASSIFICATION_DONE',
    
    // Lawyer finished reviewing => Anwalt hat Klassifizierung abgeschlossen
    FINISHED_REVIEW = 'FINISHED_REVIEW',

    // Lawyer generated min. one alternative => Alternativen wurden ausgewählt
    ALTERNATIVE_FORMULATIONS = 'ALTERNATIVE_FORMULATIONS',

    // Contract is finished => Vertrag abgeschlossen
    FINISHED = 'FINISHED',

    // State for not deleteable processes
    NOT_DELETEABLE = 'NOT_DELETEABLE',

    // State for not yet finished contracts: Lawyer wrongly marked contract as finished_review
    NOT_YET_FINISHED = 'NOT_YET_FINISHED'
}

export interface Contract {
    _id: string
    title: string
    description: string
    file: string
    state: ContractState
    owner: string
    industry: string
    createdAt: string
    updatedAt: string
    pageWidths: number[]
    pageHeights: number[]
}

export interface ContractSliceInterface {
    filteredAssessment: boolean[]
    selectedContract: Contract | null
}
