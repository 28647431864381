import {Box, Card, Grid} from '@mui/material';
import {useParams} from 'react-router-dom';
import {Assessment} from '../../../features/Clause/types';
import {useGetContractByIdQuery, useGetParagraphsAndClausesQuery, useUpdateContractMutation} from '../../../features/Contract/ContractApiSlice';
import {ContractState} from '../../../features/Contract/types';
import {type Paragraph} from '../../../features/Paragraph/types';
import {useAppSelector} from '../../../hooks/typedReduxHooks';
import LoadingComponent from '../../general/LoadingComponent';
import LoadingSpinner from '../../general/LoadingSpinner';
import ClassificationAndChangeReset from './ClassificationAndChangeReset';
import ClassificationInfo from './ClassificationInfo';
import ParagraphsAndClauses from './ParagraphsAndClauses';
import PdfViewer from './PdfViewer';

const ContractOcrDisplay = (): any => {
    const {_id} = useParams();
    const {
        data: contract,
        isLoading: isLoadingContract,
        refetch: refetchContract
    } = useGetContractByIdQuery(_id);

    const {
        data: dataParagraphs,
        refetch: refetchParagraphs
    } = useGetParagraphsAndClausesQuery(_id ?? '');

    const [updateContract] = useUpdateContractMutation();
    let paragraphs: Paragraph[] = dataParagraphs ?? [];

    // FILTER THE ASSESSMENT OF THE PARAGRAPHS
    const filteredAssessment: boolean[] = useAppSelector((state) => state.contract.filteredAssessment);

    // DONT FILTER IF NONE CLASSIFICATION IS SELECTED
    if (!filteredAssessment.every(item => !item)) {
        paragraphs = paragraphs.map(paragraph => {
            // ONLY SHOW CLAUSES WHICH ARE FILTERED FOR
            const filteredClauses = paragraph.clauses.filter(clause => {
                const latestReview = clause.latestReview;
                const assessment = latestReview?.assessment;
                return filteredAssessment[Object.values(Assessment).indexOf(assessment as Assessment)] && !clause.changed;
            });

            return {
                ...paragraph,
                clauses: filteredClauses
            };
        }).filter(paragraph => paragraph.clauses.length > 0);
    }

    const finishContract = async (): Promise<void> => {
        try {
            await updateContract({
                contractId: _id ?? '',
                requestBody: { state: ContractState.FINISHED_REVIEW }
            })
        } catch (error) {
            console.error('Failed to finish contract:', error);
        } finally {
            refetchContract()
        }
    }

    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%'
        }}>
            {isLoadingContract || contract === undefined ?
                <LoadingSpinner/> :
                <Box display={'flex'} flexDirection={'column'} alignItems={'center'} width={'100%'}>
                    <Card sx={{
                        width: '100%',
                        marginBottom: 1
                    }}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                        }}>
                            <ClassificationInfo contractId={_id ?? ''}/>
                            <ClassificationAndChangeReset contractId={_id ?? ''} contractState={contract.state} handleFinishContract={finishContract}/>
                        </Box>
                    </Card>
                    <Grid container spacing={2} justifyContent={'center'} sx={{
                        height: '75vh'
                    }}>
                        <Grid item xs={6} md={6} lg={6} sx={{
                            overflow: 'hidden',
                            height: '100%'
                        }}>
                            <PdfViewer/>
                        </Grid>
                        <Grid item xs={6} md={6} lg={6} sx={{
                            height: '100%'
                        }}>
                            {
                                contract.state === ContractState.PDF_UPLOADED ? <LoadingComponent text="OCR wird durchgeführt... Das kann etwas dauern."/> : 
                                contract.state === ContractState.OCR_DONE ? <LoadingComponent text="Vertrag wird von LLM klassifiziert... Das kann etwas dauern."/> :
                                contract.state === ContractState.NOT_DELETEABLE ? <LoadingComponent text="Vertrag wird verarbeitet."/> :
                                <ParagraphsAndClauses paragraphs={paragraphs} isClassification={true}/>
                            }
                        </Grid>
                    </Grid>
                </Box>}
        </ Box>);
};

export default ContractOcrDisplay;
